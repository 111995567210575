import React, {createContext, useContext} from 'react';
import {useConfirmationDialog} from 'shared';
import {ConfirmationOption} from 'shared/components/ConfirmationDialog/ConfirmationDialog';

interface DialogContextType {
    showDialog?: (options: ConfirmationOption) => void;
    hideDialog?: () => void;
}

export const DialogContext = createContext<DialogContextType>({});

export const useDialogContext = () => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error(
            'useDialogContext must be used within a DialogProvider'
        );
    }
    return context;
};

export const DialogProvider = ({children}: {children: JSX.Element}) => {
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();

    return (
        <DialogContext.Provider value={{showDialog, hideDialog}}>
            {children}
            {dialog}
        </DialogContext.Provider>
    );
};
